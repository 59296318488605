"use client";

import Link from "next/link";

export function DualHeading({
  title,
  subtitle,
  link,
}: {
  title: string;
  subtitle: string;
  link?: string;
}) {
  return (
    <div className="flex justify-between">
      <h2 className="flex flex-col md:flex-row gap-x-2 text-peppyhop-800 text-2xl md:text-3xl font-bold">
        <span className="text-transparent from-peppyhop-600 to-peppyhop-500 bg-gradient-90 bg-clip-text text-2xl md:text-3xl font-bold">
          {title}
        </span>
        <span>{subtitle}</span>
      </h2>
      {link ? (
        <div className="flex border-grey-300 min-w-[90px] h-max rounded-full border bg-white px-[14px] py-2 align-self-end">
          <Link
            className="text-grey-700 text-sm font-bold hover:no-underline"
            href={link}
          >
            View All
          </Link>
        </div>
      ) : null}
    </div>
  );
}
