import { objectEntries } from "ts-extras";

export function objWithValidValues(
  data: Record<string, string | boolean | number | undefined | null>
) {
  return objectEntries(data).reduce(
    (acc, [k, v]) => {
      if (v) {
        acc[k] = v.toString();
      }
      return acc;
    },
    {} as Record<string, string>
  );
}
