"use client";

import { cn } from "@/lib/utils";
import type { PaginatedProducts } from "@peppy/core/types/products.type";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { PriceDiv } from "./PriceDiv";
import { Card, CardContent } from "./ui/card";
import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
} from "./ui/carousel";

export function HeroCarousel({
  slides,
}: {
  slides: PaginatedProducts["data"];
}) {
  const [api, setApi] = useState<CarouselApi>();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    api.on("select", () => {
      setSelectedIndex(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <div className="flex flex-1 md:justify-end">
      <div className="flex flex-col gap-y-2 w-full md:w-[440px]">
        <Carousel setApi={setApi}>
          <CarouselContent className="ml-0 w-full">
            {slides.map((product, index) => {
              return (
                <CarouselItem className="pl-0" key={product.id}>
                  <Card className="flex overflow-hidden">
                    <CardContent className="flex w-full relative p-0 aspect-square">
                      <div className="flex flex-col flex-1 items-center relative">
                        <Image
                          className={cn(
                            "object-cover self-start w-full",
                            product.vertical === "clothing"
                              ? "object-top"
                              : "object-center",
                            product.vertical === "clothing"
                              ? "object-top"
                              : "object-center"
                          )}
                          height={440}
                          width={440}
                          src={product.images[0].src}
                          alt={product.title}
                          style={{ objectFit: "cover" }}
                          priority={index === 0}
                        />
                        <Link
                          href={`/shop/products/${product.slug}`}
                          className="flex absolute bottom-0 gap-x-4 justify-between left-0 px-6 self-start h-[5.785rem] w-full bg-white/80 backdrop-blur-md	"
                        >
                          <div className="flex flex-col justify-center">
                            <p className="font-bold leading-6 text-gray-900 truncate max-w-[16ch] md:max-w-[24ch]">
                              {product.title}
                            </p>
                            <p className="text-sm text-gray-500">
                              {product.store.name}
                            </p>
                          </div>
                          <div className="flex items-center justify-center">
                            <div className="bg-white rounded-2xl py-[0.125rem] px-2 border-gray-200 border-[1.5px]">
                              <PriceDiv
                                className="text-gray-700 text-sm md:text-base font-semibold md:font-bold"
                                price={product!.retailPrice}
                              />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              );
            })}
          </CarouselContent>
        </Carousel>
        <div className="flex justify-center items-center gap-x-4">
          {slides.map((slide, index) => (
            <button
              type="button"
              className={cn(
                "h-2 w-2 rounded-full bg-gray-600 embla__dot",
                selectedIndex === index && "bg-peppyhop-600 w-6 is-selected"
              )}
              onClick={() => {
                api?.scrollTo(index);
              }}
              key={slide.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
