"use client";

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { objWithValidValues } from "@/utils/obj";
import { snakeToTitleCase } from "@/utils/string";
import * as AdultFemaleConstant from "@peppy/nlp/adult-female/constants";
import * as AdultMaleConstant from "@peppy/nlp/adult-male/constants";
import type { PVCM } from "@peppy/nlp/nlp-types";
import { flatten } from "flat";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import * as R from "remeda";

type FilterOption = {
  title: string;
  breadcrumb: string;
  queryParams: Partial<{
    audience: "adult_male" | "adult_female";
    vertical: string;
    category: string;
    subCategory: string;
    color: string;
    material: string;
  }>;
};

function generateOptions(audience: "women" | "men" | "category", pvcm: PVCM) {
  const opts = flatten(
    {
      [audience]: pvcm,
    },
    {
      safe: true,
      delimiter: " → ",
    }
  ) as Record<string, Array<string> | Record<string, Array<string>>>;

  const filteredOpts = Object.fromEntries(
    Object.entries(opts).filter(
      ([_, value]) => Array.isArray(value) && value.length > 0
    )
  ) as Record<string, Array<string>>;

  const result = R.uniqueBy(
    R.entries(filteredOpts).flatMap(([key, valArr]) => {
      let result: FilterOption[] = [];
      const arr = key.split(" → ");
      if (arr[2]) {
        result.push({
          title: `${arr[2]}`,
          breadcrumb: `${arr[0]} → ${arr[1]}`,
          queryParams: objWithValidValues({
            audience:
              arr[0] === "men"
                ? "adult_male"
                : arr[0] === "women"
                  ? "adult_female"
                  : null,
            vertical: arr[1],
            category: arr[2],
            subCategory: undefined,
            color: undefined,
            material: undefined,
          }),
        });
      }
      const subArr: FilterOption[] = valArr.map((val) => {
        const title = `${val} ${key.split(" → ").pop()}`;
        const arr = key.split(" → ");
        return {
          title: snakeToTitleCase(title),
          breadcrumb: key,
          queryParams: objWithValidValues({
            audience:
              arr[0] === "men"
                ? "adult_male"
                : arr[0] === "women"
                  ? "adult_female"
                  : null,
            vertical: arr[1],
            category: arr[2],
            subCategory: val,
            color: undefined,
            material: undefined,
          }),
        };
      });
      result = [...result, ...subArr];
      return result;
    }),
    (x) => x.breadcrumb
  );

  return result;
}

export function ProductSearchInput() {
  const [open, setOpen] = useState(false);
  const [recentSearches, setRecentSearches] = useState<
    { term: string; q: string }[]
  >([]);
  const router = useRouter();

  useEffect(() => {
    setRecentSearches(
      JSON.parse(localStorage?.getItem("@peppy:recent_searches")!) ?? []
    );
  }, []);

  function redirect(title: string, queryParams: Record<string, string>) {
    localStorage?.setItem(
      "@peppy:recent_searches",
      JSON.stringify(
        [
          {
            term: title,
            q: new URLSearchParams(queryParams).toString(),
          },
          ...recentSearches,
        ].splice(0, 3)
      )
    );
    router.push(
      `/search/${title}?${new URLSearchParams(queryParams).toString()}`
    );
  }

  return (
    <div className="flex flex-col gap-y-4 w-full">
      <div className="flex w-full relative">
        <input
          type="search"
          className="w-full shadow-md h-16 rounded-full pl-14 pr-8 placeholder:text-slate-400"
          placeholder="What are you looking for ?"
          onFocus={() => setOpen(true)}
        />
        <FiSearch
          size={24}
          className="absolute left-6 top-1/2 -translate-y-1/2 text-gray-500"
        />
        <button
          type="button"
          className="absolute right-2 font-bold top-1/2 -translate-y-1/2 bg-peppyhop-500 rounded-full h-12 px-2 text-white gap-2 items-start justify-center"
        >
          Search <span className="hidden md:inline-block">Products</span>
        </button>
      </div>

      <div className="flex w-full justify-around md:justify-start gap-y-2 gap-x-4 flex-wrap">
        {recentSearches?.map((search) => (
          <Link
            className="inline-flex bg-white rounded-full px-4 py-2 text-xs items-center border shadow-sm"
            href={`/search/${search.term}?${new URLSearchParams(
              search.q
            ).toString()}`}
            key={search.term}
          >
            <FaSearch size={12} className="inline-block mr-1 text-gray-400" />
            {search.term}
          </Link>
        ))}
      </div>

      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type to search product or brand" />
        <CommandList className="max-h-[calc(100dvh-40px)] md:max-h-80">
          <CommandEmpty>No results found.</CommandEmpty>
          {recentSearches.length > 0 ? (
            <>
              <CommandGroup heading="Searched recently">
                {recentSearches.map((search) => (
                  <CommandItem
                    onSelect={() =>
                      router.push(`/search/${search.term}?${search.q}`)
                    }
                    key={search.term}
                  >
                    <span>{search.term}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
              <CommandSeparator />
            </>
          ) : null}

          <CommandGroup heading="Shop for women">
            {generateOptions("women", AdultFemaleConstant.getAll().pvcm).map(
              (item) => (
                <CommandItem
                  key={item.breadcrumb}
                  onSelect={() => redirect(item.title, item.queryParams)}
                >
                  <span>{item.title}</span>
                </CommandItem>
              )
            )}
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Shop for men">
            {generateOptions("men", AdultMaleConstant.getAll().pvcm).map(
              (item) => (
                <CommandItem
                  key={item.breadcrumb}
                  onSelect={() => redirect(item.title, item.queryParams)}
                >
                  <span>{item.title}</span>
                </CommandItem>
              )
            )}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </div>
  );
}
